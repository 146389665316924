<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  widows: 100%;
  height: 100%;
}
.advert-root .el-upload {
  width: 100%;
}
.advert-root .el-upload-dragger {
  width: 100%;
}
.shop-root .el-upload {
  width: 100%;
}
.shop-root .el-upload-dragger {
  width: 100%;
}
.el-button + .el-button {
  margin-left: 0em;
}
.el-dialog {
  min-width: 850px;
}
.el-button--text {
  margin-right: 1em;
}
.avatar-uploader-icon {
  /* border: 1px solid rgba(0, 0, 0, .08);
  border-radius: .3em;
  overflow: hidden; */
}
.avatar-uploader-icon {
  z-index: 999;
  position: relative;
}
.el-form-item__content {
  text-align: left;
}
.el-upload-dragger {
  width: auto;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-upload-dragger {
  height: 100%;
}
.el-select {
  width: 100%;
}
</style>
