import request from "../utils/request";

let prefix = "/api";

let apiList = {
  login: "/oauth/upLogin", //登录
  getCode: "/oauth/loginAuthCode", //图形验证码

  // uploadFile: '/file/uploadFile',  //文件上传

  //
  addOrg: "/sysOrg/add",
  deleteOrg: "/sysOrg/delete",
  getOrgDetail: "/sysOrg/detail",
  getOrgPage: "/sysOrg/page",
  updateOrg: "/sysOrg/update",

  //系统账户
  addUser: "/sysUser/add",
  updateUser: "/sysUser/update",
  deleteUser: "/sysUser/delete",
  getUserDetail: "/sysUser/detail",
  getUserPage: "/sysUser/page",
  resetPassword: "/sysUser/resetPassword",

  //菜单权限
  queryRoleMenu: "/sysMenu/queryRoleMenu", //查询该角色的菜单权限
  queryUserMenu: "/sysMenu/queryUserMenu", //查询用户菜单权限
  setRoleMenu: "/sysRoleMenu/set", //设置角色的菜单权限

  //角色
  addSysRole: "/sysRole/add", //角色
  updateSysRole: "/sysRole/update",
  deleteSysRole: "/sysRole/delete",
  getRoleDetail: "/sysRole/detail",
  queryRolePage: "/sysRole/page",

  //WeChat
  queryWeChatUser: "/wxUser/page",
  deleteWxUser: "/wxUser/delete",
  updateWxUser: "/wxUser/update",

  addSysConstant: "/sysConstant/add",
  updateSysConstant: "/sysConstant/update",
  deleteSysConstant: "/sysConstant/delete",
  getSysConstantDetail: "/sysConstant/detail",
  getSysConstantPage: "/sysConstant/page",

  getProjectPage: "/project/page",
  getProjectDetail: "/project/detail",

  countWorkSheet: "/workSheet/count",

  exportMonTabCount: "/project/exportMonTabCount",
  monTabCount: "/project/monTabCount",

  queryMontabProject: "/project/queryMontabProject",
  exportExcel: "/api/project/export",
};

let apiFn = {};

function generatorRequest(apiList) {
  for (let key in apiList) {
    apiFn[key] = (params) => {
      return request.post(prefix + apiList[key], params);
    };
  }
}

generatorRequest(apiList);

export default { ...apiFn };
