// 导入组件
import Vue from "vue";
import Router from "vue-router";

// 启用路由
Vue.use(Router);

// 导出路由
export default new Router({
  routes: [
    {
      path: "/",
      name: "",
      component: () => import("@/views/auth/Login.vue"),
      hidden: true,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: "/login",
      name: "登录",
      component: () => import("@/views/auth/Login.vue"),
      hidden: true,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: "/index",
      name: "首页",
      component: () => import("@/views/Index"),
      iconCls: "el-icon-tickets",
      children: [
        {
          path: "/sys/org",
          name: "机构管理",
          component: () => import("@/views/system/Org"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/role",
          name: "角色管理",
          component: () => import("@/views/system/Role"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/User",
          name: "用户管理",
          component: () => import("@/views/system/User"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/wxUser",
          name: "微信用户管理",
          component: () => import("@/views/base/WeChatUser"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/constant",
          name: "常量管理",
          component: () => import("@/views/base/Constant"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/project",
          name: "工程管理",
          component: () => import("@/views/item/Item"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/monTab",
          name: "月表",
          component: () => import("@/views/count/MonTab"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/xxCount",
          name: "信息来源统计",
          component: () => import("@/views/count/xxCount"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/gcgCount",
          name: "工程股统计",
          component: () => import("@/views/count/gcgCount"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/sys/sgdCount",
          name: "施工队统计",
          component: () => import("@/views/count/sgdCount"),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
  ],
});
