import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
  state: {
    user: false,
  },
  mutations: {
    // 登录
    login(state, user) {
      state.user = user;
      localStorage.setItem("userInfo", user);
    },
    // 退出
    // eslint-disable-next-line no-unused-vars
    logout(state, user) {
      state.user = "";
      localStorage.setItem("userInfo", "");
    },
  },
});
