import Vue from "vue";
// 引入element UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App";
// 引入路由
import router from "./router";
// 引入状态管理
import store from "./vuex/store";
// 引入icon
import "./assets/icon/iconfont.css";
import "./assets/icon/index";
import Api from "./api/api";

import "@/utils/permission.js"; //路由拦截

import VueAMap from "@vuemap/vue-amap";
import "@vuemap/vue-amap/dist/style.css";
// 初始化vue-amap

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "8185710848a6d87674da8d11da7d80df",
  plugin: ["AMap.Geolocation"],
});

Vue.prototype.$api = Api;
// 引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

// 使用element UI
Vue.use(ElementUI);

import VueParticles from "vue-particles";
Vue.use(VueParticles);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store, //使用store vuex状态管理
  components: { App },
  template: "<App/>",
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue(),
  },
});
