import axios from "axios";
import Vue from "vue";
import router from "../router";
import { getSessionStorage, clearSessionStorage } from "./storage";
axios.defaults.withCredentials = true;

const instance = axios.create({
  // baseURL: 'http://localhost:8888',
  timeout: 200000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getSessionStorage("TOKEN");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    // Vue.prototype.$console.error('Request error', error)
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // console.log(response)
    return response;
  },
  (error) => {
    if (error.response.data.code === 9999) {
      clearSessionStorage();
      router.push("/login");
      Vue.prototype.$message.error(error.response.data.message);
      return Promise.reject(error);
    }
    Vue.prototype.$message.error(error.response.data.message);
    return Promise.reject(error);
  }
);

export default instance;
